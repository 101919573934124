import React, { FC } from 'react';
import { ReactComponent as Arrow } from '../../Components/Icons/newArrow.svg';
import './MainButton.scss';
import { Link } from 'react-router-dom';

type Props = {
	text: string;
	filled?: boolean;
	white?: boolean;
	showArrow?: boolean;
	border?: boolean;
	route?: string;
	style?: React.CSSProperties;
	className?: string;
};

export const MainButton: FC<Props> = ({
	filled,
	text,
	white,
	route,
	showArrow = true,
	border = true,
	style,
	className = '',
}) => {
	return route && route.startsWith('http') ? (
		<a
			href={route}
			target="_blank"
			rel="noopener noreferrer"
			className={`${className} ${filled ? 'filledMainButton' : white ? 'whiteButton' : 'mainButton'} ${
				border ? '' : 'borderNone'
			}`}	
			>
			{text}
			{showArrow && <Arrow />}
		</a>
	) : (
		<Link
			style={style}
			to={route ?? ''}
			className={`${className} ${filled ? 'filledMainButton' : white ? 'whiteButton' : 'mainButton'} ${
				border ? '' : 'borderNone'
			}`}
		>
			{text}
			{showArrow && <Arrow />}
		</Link>
	);
};
