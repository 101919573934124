import React, { useState } from 'react';

import { NavLink } from 'react-router-dom';
import { MainButton } from '../MainButton/MainButton';

import OutsideClickHandler from 'react-outside-click-handler';

import { ReactComponent as MenuIcon } from '../../Components/Icons/newMenu.svg';

import motto from './images/motto.png';
import logo from './images/logo.png';
import education from './images/education.png';
import legal from './images/legal.png';
import graphic from './images/graphic.png';
import customerops from './images/customerops.png';
import finance from './images/finance.png';
import customer from './images/customer.png';
import writing from './images/writing.png';
import thech from './images/thech.png';
import sysadmin from './images/sysadmin.png';
import hr from './images/hr.png';
import management from './images/management.png';
import logistic from './images/logistic.png';
import photo from './images/photo.png';
import qa from './images/qa.png';
import soft from './images/soft.png';
import sales from './images/sales.png';

import './Header.scss';
import { Menu } from './Components/Menu/Menu';

export default function Header() {
	const [hireStaff, setHireStaff] = useState(false);

	const [menuOpen, setMenuOpen] = useState(false);

	return (
		<>
			<div className="header">
				<div className="headerMotto">
					<div
						className="mottoImage"
						style={{
							backgroundImage: `url(${motto})`,
						}}
					/>
				</div>
				<div className="newHeaderContent">
					<div className="newHeaderContentWrapper">
						<NavLink to="/v2" className="logoLinkWrapper">
							<div
								className="logoImage"
								style={{
									backgroundImage: `url(${logo})`,
								}}
							/>
						</NavLink>

						<div className="newHeaderMenuWrapper">
							<div className="topLink">
								<div className="hireStaffLinkWrapper">
									<NavLink
										to="/v2/hirestaff"
										onMouseEnter={() => setHireStaff(true)}
										className="headerTopLink"
										activeClassName="activeHeaderLink"
									>
										Hire Staff
									</NavLink>
									{hireStaff && (
										<OutsideClickHandler onOutsideClick={() => setHireStaff(false)}>
											<div onMouseLeave={() => setHireStaff(false)} className="hireStaffContainer">
												<NavLink to="/v2/test" className="hireStaffLink">
													<div
														className="hireStaffImage"
														style={{
															backgroundImage: `url(${sales})`,
														}}
													/>
													<span>Sales & Marketing</span>
												</NavLink>
												<NavLink to="/v2/test" className="hireStaffLink">
													<div
														className="hireStaffImage"
														style={{
															backgroundImage: `url(${hr})`,
														}}
													/>
													<span>HR</span>
												</NavLink>
												<NavLink to="/v2/test" className="hireStaffLink">
													<div
														className="hireStaffImage"
														style={{
															backgroundImage: `url(${finance})`,
														}}
													/>
													<span>Finance & Accounting</span>
												</NavLink>
												<NavLink to="/v2/test" className="hireStaffLink">
													<div
														className="hireStaffImage"
														style={{
															backgroundImage: `url(${soft})`,
														}}
													/>
													<span>Software Development</span>
												</NavLink>
												<NavLink to="/v2/test" className="hireStaffLink">
													<div
														className="hireStaffImage"
														style={{
															backgroundImage: `url(${sysadmin})`,
														}}
													/>
													<span>System Administration & DevOps & Cloud</span>
												</NavLink>
												<NavLink to="/v2/test" className="hireStaffLink">
													<div
														className="hireStaffImage"
														style={{
															backgroundImage: `url(${customerops})`,
														}}
													/>
													<span>Customer Support, Ops & VAs</span>
												</NavLink>
												<NavLink to="/v2/test" className="hireStaffLink">
													<div
														className="hireStaffImage"
														style={{
															backgroundImage: `url(${qa})`,
														}}
													/>
													<span>QA</span>
												</NavLink>
												<NavLink to="/v2/test" className="hireStaffLink">
													<div
														className="hireStaffImage"
														style={{
															backgroundImage: `url(${thech})`,
														}}
													/>
													<span>Technical Support</span>
												</NavLink>
												<NavLink to="/v2/test" className="hireStaffLink">
													<div
														className="hireStaffImage"
														style={{
															backgroundImage: `url(${graphic})`,
														}}
													/>
													<span>Graphic Design</span>
												</NavLink>
												<NavLink to="/v2/test" className="hireStaffLink">
													<div
														className="hireStaffImage"
														style={{
															backgroundImage: `url(${photo})`,
														}}
													/>
													<span>Photo & Video</span>
												</NavLink>
												<NavLink to="/v2/test" className="hireStaffLink">
													<div
														className="hireStaffImage"
														style={{
															backgroundImage: `url(${writing})`,
														}}
													/>
													<span>Writing & Translation</span>
												</NavLink>
												<NavLink to="/v2/test" className="hireStaffLink">
													<div
														className="hireStaffImage"
														style={{
															backgroundImage: `url(${legal})`,
														}}
													/>
													<span>Legal</span>
												</NavLink>
												<NavLink to="/v2/test" className="hireStaffLink">
													<div
														className="hireStaffImage"
														style={{
															backgroundImage: `url(${logistic})`,
														}}
													/>
													<span>Logistic</span>
												</NavLink>
												<NavLink to="/v2/test" className="hireStaffLink">
													<div
														className="hireStaffImage"
														style={{
															backgroundImage: `url(${customer})`,
														}}
													/>
													<span>Customer Support</span>
												</NavLink>
												<NavLink to="/v2/test" className="hireStaffLink">
													<div
														className="hireStaffImage"
														style={{
															backgroundImage: `url(${education})`,
														}}
													/>
													<span>Education & eLearning</span>
												</NavLink>
												<NavLink to="/v2/test" className="hireStaffLink">
													<div
														className="hireStaffImage"
														style={{
															backgroundImage: `url(${management})`,
														}}
													/>
													<span>Management & Operations</span>
												</NavLink>
											</div>
										</OutsideClickHandler>
									)}
								</div>

								<NavLink to="/v2/whyus" className="headerTopLink" activeClassName="activeHeaderLink">
									Why Us
								</NavLink>
								<NavLink to="/v2/remotestaffing" className="headerTopLink" activeClassName="activeHeaderLink">
									Why Remote Staffing
								</NavLink>
								<NavLink to="/v2/successstories" className="headerTopLink" activeClassName="activeHeaderLink">
									Success Stories
								</NavLink>
								<NavLink to="/v2/jobopening" className="headerTopLink" activeClassName="activeHeaderLink">
									Job Openings
								</NavLink>
							</div>
							<div className="bottomLinks">
								<NavLink to="/v2" className="newHeaderLink" activeClassName="activeHeaderLink">
									Home
								</NavLink>
								<NavLink to="/v2/blog" className="newHeaderLink" activeClassName="activeHeaderLink">
									Blog
								</NavLink>
								<NavLink to="/v2/event" className="newHeaderLink" activeClassName="activeHeaderLink">
									Events
								</NavLink>
								<NavLink to="/v2/aboutus" className="newHeaderLink" activeClassName="activeHeaderLink">
									About Us
								</NavLink>
								<NavLink to="/v2/contactus" className="newHeaderLink" activeClassName="activeHeaderLink">
									Contact Us
								</NavLink>
							</div>
						</div>
					</div>
					<div className="headerMainButtonsWrapper">
						<MainButton text="START HIRING" filled={true} />
						<MainButton text="GET HIRED" route="/v2/gethired" />
					</div>
					<div className="headerMenu" onClick={() => setMenuOpen(true)}>
						<MenuIcon />
					</div>
				</div>
			</div>
			<Menu menuOpened={menuOpen} setMenuOpened={setMenuOpen} />
		</>
	);
}
