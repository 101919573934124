import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { FirestoreCollection } from '@react-firebase/firestore';
import { Link, NavLink, useLocation } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import Media from 'react-media';

import Social from '../Social/Social';
import MenuModal from './MenuModal/MenuModal';
import Covid from '../common/Covid/Covid';

import { ReactComponent as Logo } from '../Icons/newLogo.svg';
import { ReactComponent as LogoTransparent } from '../Icons/newLogo.svg';
import { ReactComponent as Right } from '../Icons/right.svg';
import { ReactComponent as Menu } from '../Icons/menu.svg';
import { ReactComponent as MenuBlack } from '../Icons/menuBlack.svg';

import './Header.scss';

interface HeaderProps {
	setCovidOpened: Dispatch<SetStateAction<boolean>>;
	covidOpened: boolean;
}

export default function Header({ setCovidOpened, covidOpened }: HeaderProps) {
	const [menuOpened, setMenuOpened] = useState(false);
	const [headerChanged, setHeaderChanged] = useState(false);
	const [expertiseDropdown, setExpertiseDropdown] = useState(false);
	const [aboutDropdown, setAboutDropdown] = useState(false);
	const location = useLocation();

	const listenScrollEvent = () => {
		if (window.scrollY < 70) {
			return setHeaderChanged(false);
		} else if (window.scrollY > 140) {
			return setHeaderChanged(true);
		}
	};

	useEffect(() => {
		window.addEventListener('scroll', listenScrollEvent);

		return () => window.removeEventListener('scroll', listenScrollEvent);
	}, []);

	return (
		<>
			<div className={location.pathname === '/' ? 'homeHeader' : 'header'}>
				<div className={headerChanged ? 'stickyHeaderContent' : 'headerContent'}>
					<NavLink to="/" className="logoWrapper">
						{location.pathname === '/' && !headerChanged ? (
							<LogoTransparent className="logoIcon" />
						) : (
							<Logo className="logoIcon" />
						)}
					</NavLink>
					<Media query="(max-width: 930px)">
						{(matches) =>
							!matches ? (
								<>
									<div className="headerMenuWrapper">
										<div
											className="headerLinkWrapper"
											onMouseEnter={() => setAboutDropdown(true)}
											onMouseLeave={() => setAboutDropdown(false)}
										>
											<NavLink
												to="/about"
												className={aboutDropdown ? 'headerLinkOpened' : 'headerLink'}
												activeClassName="activeHeaderLink"
											>
												About <Right />
											</NavLink>
											{aboutDropdown && (
												<div
													className={
														location.pathname === '/' && !headerChanged ? 'stickyHeaderLinkDropdown' : 'headerLinkDropdown'
													}
												>
													<div className="headerDropdownMenu">
														<HashLink to="/about#company" className="menuDropdownLink">
															Company
														</HashLink>
														<Link to="/team" className="menuDropdownLink">
															The team
														</Link>
													</div>
												</div>
											)}
										</div>
										<div className="headerDevider" />
										<div
											className="headerLinkWrapper"
											onMouseEnter={() => setExpertiseDropdown(true)}
											onMouseLeave={() => setExpertiseDropdown(false)}
										>
											<NavLink
												to="/services"
												className={expertiseDropdown ? 'headerLinkOpened' : 'headerLink'}
												activeClassName="activeHeaderLink"
											>
												Services <Right />
											</NavLink>
											{expertiseDropdown && (
												<div
													className={
														location.pathname === '/' && !headerChanged
															? 'stickyHeaderLinkDropdownService'
															: 'headerLinkDropdownService'
													}
												>
													<div className="headerDropdownMenu">
														<Link to="/service/recruitment" className="menuDropdownLink">
															Recruitment
														</Link>
														<Link to="/service/itDevelopment" className="menuDropdownLink">
															IT Development
														</Link>
														<Link to="/service/odooSpecialists" className="menuDropdownLink">
															Odoo ERP Specialists
														</Link>
														<Link to="/service/support" className="menuDropdownLink">
															Customer Service Support
														</Link>
														<Link to="/service/ict-management" className="menuDropdownLink">
															ICT Management
														</Link>
														<Link to="/service/sales" className="menuDropdownLink">
															Sales and Internet Marketing
														</Link>
														<Link to="/service/project-coordination" className="menuDropdownLink">
															Project Coordination
														</Link>
														<Link to="/service/human-resources" className="menuDropdownLink">
															Human Resources
														</Link>
													</div>
												</div>
											)}
										</div>
										<div className="headerDevider" />
										<div className="headerLinkWrapper">
											<NavLink to="/contact" className="headerLink" activeClassName="activeHeaderLink">
												<HashLink to="/contact#contactPlanner" className="headerLink">
													Contact
												</HashLink>
											</NavLink>
										</div>
										<div className="headerDevider" />
										<div className="headerLinkWrapper">
											<NavLink to="/myteam" className="headerLink" activeClassName="activeHeaderLink">
												Build a team
											</NavLink>
										</div>

										<div className="headerDevider" />
										<div className="headerLinkWrapper">
											<NavLink to="/blog" className="headerLink" activeClassName="activeHeaderLink">
												Blog
											</NavLink>
										</div>
										<div className="headerDevider" />
										<div className="headerLinkWrapper">
											<NavLink to="/vacancies" className="headerLink" activeClassName="activeHeaderLink">
												Job Opening
											</NavLink>
										</div>
										<div className="headerDevider" />
										<div className="headerLinkWrapper">
											<NavLink to="/joinus" className="headerLink" activeClassName="activeHeaderLink">
												<HashLink to="/joinus#joinUsPlanner" className="headerLink">
													Join Us
												</HashLink>
											</NavLink>
										</div>
									</div>
									<div className="infoWithSocialWrapper">
										<FirestoreCollection path="/infoModal/">
											{(infoData) => {
												if (infoData.isLoading) {
													return;
												}
												return infoData.value[0].shown && infoData.value[0].image.original.src ? (
													<div className="headerCovidWrapper">
														<button onClick={() => setCovidOpened(true)} className="headerCovidButton">
															<img src={infoData.value[0].image.original.src} alt="" />
														</button>
														{covidOpened && <Covid setCovidOpened={setCovidOpened} {...infoData.value[0]} />}
													</div>
												) : null;
											}}
										</FirestoreCollection>
										<div className="socialIconsWrapper">
											<Social />
										</div>
									</div>
								</>
							) : (
								<div className="menuHeaderWrapper">
									<FirestoreCollection path="/infoModal/">
										{(infoData) => {
											if (infoData.isLoading) {
												return;
											}

											return infoData.value[0].shown && infoData.value[0].image.original.src ? (
												<div className="headerCovidWrapper">
													<div onClick={() => setCovidOpened(true)} className="headerCovidButton">
														<img src={infoData.value[0].image.original.src} alt="" />
													</div>
													{covidOpened && <Covid setCovidOpened={setCovidOpened} {...infoData.value[0]} />}
												</div>
											) : null;
										}}
									</FirestoreCollection>
									<div onClick={() => setMenuOpened(true)} className="menuHeader">
										{location.pathname === '/' && !headerChanged ? <Menu /> : <MenuBlack />}
									</div>
									<MenuModal
										menuOpened={menuOpened}
										setMenuOpened={setMenuOpened}
										setCovidOpened={setCovidOpened}
										covidOpened={covidOpened}
									/>
								</div>
							)
						}
					</Media>
				</div>
			</div>
		</>
	);
}
