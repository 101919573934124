import React from 'react';
import Modal from '../../../../Components/common/Modal/Modal';
import { ReactComponent as CloseBtn } from '../../../../Components/Icons/closeBtn.svg';
import { NavLink } from 'react-router-dom';

import './Menu.scss';
import { MainButton } from '../../../MainButton/MainButton';

export function Menu({
	menuOpened,
	setMenuOpened,
}: {
	menuOpened: boolean;
	setMenuOpened: React.Dispatch<React.SetStateAction<boolean>>;
}) {
	return (
		<Modal isOpen={menuOpened} onRequestClose={() => setMenuOpened(false)} className="newMenuModalOverlay">
			<div
				onClick={() => {
					setMenuOpened(false);
				}}
				className="newMenuClose"
			>
				<CloseBtn />
			</div>
			<div className="menuLinksWrapper">
				<NavLink to="/v2/hirestaff" className="menuLink boldLink">
					Hire Staff
				</NavLink>
				<NavLink to="/v2/whyus" className="menuLink boldLink">
					Why Us
				</NavLink>
				<NavLink to="/v2/remotestaffing" className="menuLink boldLink">
					Why Remote Staffing
				</NavLink>
				<NavLink to="/v2/successstories" className="menuLink boldLink">
					Success Stories
				</NavLink>
				<NavLink to="/v2/jobopening" className="menuLink boldLink">
					Job Openings
				</NavLink>

				<NavLink to="/v2" className="menuLink">
					Home
				</NavLink>
				<NavLink to="/v2/blog" className="menuLink">
					Blog
				</NavLink>
				<NavLink to="/v2/event" className="menuLink">
					Events
				</NavLink>
				<NavLink to="/v2/aboutus" className="menuLink">
					About Us
				</NavLink>
				<NavLink to="/v2/contactus" className="menuLink">
					Contact Us
				</NavLink>
			</div>

			<div className="menuBtnWrapper">
				<MainButton text="START HIRING" filled={true} />
				<MainButton text="GET HIRED" route="/v2/gethired" />
			</div>
		</Modal>
	);
}
